.box {
  height: 100%;
  width: 100%;
}

.click-disabled {
  pointer-events: none;
}
.click-enabled {
  pointer-events: auto;
}

.relative {position: relative;}
.absolute {position: absolute;}
.fixed {position: fixed;}
.static{position: static;}


.oflow-auto {overflow: auto;}
.no-scroll {overflow: hidden;}
.scrollY{overflow-y: scroll;}
.scrollX{overflow-x: scroll;}

.nowrap {white-space: nowrap;}

.wh100{
  width: 100%;
  height: 100%;
}
.winh{width: inherit;}
.w100{width: 100%;}
.w95{width: 95%;}
.w90{width: 90%;}
.w85{width: 85%;}
.w80{width: 80%;}
.w75{width: 75%;}
.w70{width: 70%;}
.w60{width: 60%;}
.w50{width: 50%;}
.w45{width: 45%;}
.w40{width: 40%;}
.w35{width: 35%;}
.w30{width: 30%;}
.w25{width: 25%;}
.w20{width: 20%;}
.w15{width: 15%;}
.w10{width: 10%;}
.w10p{width: 10px;}
.w20p{width: 20px;}
.w30p{width: 30px;}
.w40p{width: 40px;}
.w50p{width: 50px;}
.w60p{width: 60px;}
.w70p{width: 70px;}
.w80p{width: 80px;}
.w90p{width: 90px;}
.w100p{width: 100px;}
.w150p{width: 150px;}
.w150p{width: 150px;}
.w200p{width: 200px;}
.w300p{width: 300px;}
.w400p{width: 400px;}
.h100{height: 100%;}
.h90{height: 90%;}
.h80{height: 80%;}
.h70{height: 70%;}
.h65{height: 65%;}
.h60{height: 60%;}
.h50{height: 50%;}
.h40{height: 40%;}
.h30{height: 30%;}
.h20{height: 20%;}
.h10{height: 10%;}
.h100p{height: 100px;}
.h10p{height: 10px;}
.h20p{height: 20px;}
.h30p{height: 30px;}
.h40p{height: 40px;}
.h41p{height: 41px;}
.h42p{height: 42px;}
.h50p{height: 50px;}
.h200p{height: 200px;}
.h300p{height: 300px;}



.block{display: block;}
.inline-block{display: inline-block;}
.inline{display: inline;}
.flex{
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.flex-wrap {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.flex-start {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.inline-flex{
  display: inline-flex;
  display: -webkit-inline-flex;
}
.flex-auto { flex: 0 0 auto; }
.flex-max { flex: 1 0 auto; }
.hide{display: none!important;}
.hide-imp{display: none!important;}
.no-show{visibility: hidden;}
.do-show{visibility: visible;}
.show-flex{display: flex;}
.show{display: block;}
.align-end{align-items: flex-end;}
.align-start{align-items: flex-start;}


.reset{margin: 0; padding: 0;}
.m-auto{margin: 0 auto;}
.m0{margin: 0;}
.p0{padding: 0;}

.mt0{margin-top: 0;}
.mtop1px{margin-top: 1px;}
.mtop2px{margin-top: 2px;}
.mtop3px{margin-top: 3px;}
.mtop4px{margin-top: 4px;}
.mtop4px{margin-top: 4px;}
.mtop5px{margin-top: 5px;}
.mtop8px{margin-top: 8px;}
.mtop9px{margin-top: 9px;}
.mtop10px{margin-top: 10px;}
.mtop12px{margin-top: 12px;}
.mtop14px{margin-top: 14px;}
.mtop15px{margin-top: 15px;}
.mtop16px{margin-top: 16px;}
.mtop18px{margin-top: 18px;}
.mtop20px{margin-top: 20px;}
.mtop25px{margin-top: 25px;}
.mtop30px{margin-top: 30px;}
.mtop35px{margin-top: 35px;}
.mtop40px{margin-top: 40px;}
.mtop45px{margin-top: 45px;}
.mtop50px{margin-top: 50px;}
.mtop100px{margin-top: 100px;}



.mleftauto{margin-left: auto;}
.mleft0{margin-left: 0;}
.mleft1px{margin-left: 1px;}
.mleft2px{margin-left: 2px;}
.mleft3px{margin-left: 3px;}
.mleft4px{margin-left: 4px;}
.mleft5px{margin-left: 5px;}
.mleft6px{margin-left: 6px;}
.mleft8px{margin-left: 8px;}
.mleft10px{margin-left: 10px;}
.mleft15px{margin-left: 15px;}
.mleft20px{margin-left: 20px;}
.mleft40px{margin-left: 40px;}
.mleft50px{margin-left: 50px;}
.mleft100px{margin-left: 100px;}
.mleft150px{margin-left: 150px;}
.mleft200px{margin-left: 200px;}

.mrightauto{margin-right: auto;}
.mright0{margin-right: 0;}
.mright1px{margin-right: 1px;}
.mright5px{margin-right: 5px;}
.mright7px{margin-right: 7px;}
.mright8px{margin-right: 8px;}
.mright10px{margin-right: 10px;}
.mright12px{margin-right: 12px;}
.mright20px{margin-right: 20px;}
.mright30px{margin-right: 30px;}
.mright40px{margin-right: 40px;}
.mright50px{margin-right: 50px;}
.mright100px{margin-right: 100px;}

.mbottom0{margin-bottom: 0;}
.mbottom50px{margin-bottom: 50px;}
.mbottom100px{margin-bottom: 100px;}

.ptop0{padding-top: 0;}
.pleft0{padding-left: 0;}
.pleft0imp{padding-left: 0!important;}
.pright0{padding-right: 0;}
.pbottom0{padding-bottom: 0;}

.ptop50px{padding-top: 50px;}
.ptop100px{padding-top: 100px;}

.pbott1px{padding-bottom: 1px;}
.pbott2px{padding-bottom: 2px;}
.pbott3px{padding-bottom: 3px;}
.pbott4px{padding-bottom: 4px;}
.pbott5px{padding-bottom: 5px;}
.pbott6px{padding-bottom: 6px;}
.pbott7px{padding-bottom: 7px;}
.pbott8px{padding-bottom: 8px;}
.pbott9px{padding-bottom: 9px;}
.pbott10px{padding-bottom: 10px;}

.pleft1px{padding-left: 1px;}
.pleft2px{padding-left: 2px;}
.pleft3px{padding-left: 3px;}
.pleft4px{padding-left: 4px;}
.pleft5px{padding-left: 5px;}
.pleft6px{padding-left: 6px;}
.pleft7px{padding-left: 7px;}
.pleft8px{padding-left: 8px;}
.pleft9px{padding-left: 9px;}
.pleft10px{padding-left: 10px;}
.pleft20px{padding-left: 20px;}
.pleft50px{padding-left: 50px;}
.pleft100px{padding-left: 100px;}

.pright1px{padding-right: 1px;}
.pright2px{padding-right: 2px;}
.pright3px{padding-right: 3px;}
.pright4px{padding-right: 4px;}
.pright5px{padding-right: 5px;}
.pright6px{padding-right: 6px;}
.pright7px{padding-right: 7px;}
.pright8px{padding-right: 8px;}
.pright9px{padding-right: 9px;}
.pright10px{padding-right: 10px;}
.pright15px{padding-right: 15px;}
.pright20px{padding-right: 20px;}
.pright50px{padding-right: 50px;}
.pright100px{padding-right: 100px;}

.pbottom50px{padding-bottom: 50px;}
.pbottom100px{padding-bottom: 100px;}

.right0{right: 0;}
.top0{top: 0;}
.left0{left: 0;}
.left3{left: 3px;}
.bottom0{bottom: 0;}

.mbott1px{margin-bottom: 1px;}
.mbott2px{margin-bottom: 2px;}
.mbott3px{margin-bottom: 3px;}
.mbott4px{margin-bottom: 4px;}
.mbott5px{margin-bottom: 5px;}
.mbott8px{margin-bottom: 8px;}
.mbott10px{margin-bottom: 10px;}
.mbott15px{margin-bottom: 15px;}
.mbott20px{margin-bottom: 20px;}
.mbott30px{margin-bottom: 30px;}
.mbott40px{margin-bottom: 40px;}
.mbott50px{margin-bottom: 50px;}

.ptop1px{padding-top: 1px;}
.ptop2px{padding-top: 2px;}
.ptop3px{padding-top: 3px;}
.ptop4px{padding-top: 4px;}
.ptop5px{padding-top: 5px;}
.ptop6px{padding-top: 6px;}
.ptop20px{padding-top: 20px;}
.ptop30px{padding-top: 30px;}
.ptop40px{padding-top: 40px;}
.ptop50px{padding-top: 50px;}

.pbott10px{padding-bottom: 10px;}
.pbott20px{padding-bottom: 20px;}
.pbott30px{padding-bottom: 30px;}
.pbott40px{padding-bottom: 40px;}
.pbott50px{padding-bottom: 50px;}




.flex-center{
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-justify-content: center;
  align-content: center;
  -webkit-align-content: center;
  align-items: center;
  -webkit-align-items: center;
}
.flex-start{
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
}
.flex-end{
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  align-content: flex-end;
  -webkit-align-content: flex-end;
}
.flex-between{
  justify-content: space-between;
  align-content: space-between;
  -webkit-justify-content: space-between;
  -webkit-align-content: space-between;
}
.flex-around{
  justify-content: space-around;
  align-content: space-around;
  -webkit-justify-content: space-around;
  -webkit-align-content: space-around;
}
.flex-wrap{
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.flex-no-wrap{
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}
.justify-center {
  justify-content: center;
  -webkit-justify-content: center;
}
.align-center {
  align-items: center;
  -webkit-align-items: center;
}
.columns{
  flex-direction: column;
  -webkit-flex-direction: column;
}
.row{
  flex-direction: row;
  -webkit-flex-direction: row;
}
.m-left-auto {
  margin-left: auto;
}
.align-self-center {
  align-self: center;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-justify{
  text-align: justify;
  text-justify: inter-word;
}

.bg-transparent{background: transparent!important;}
.bg-blue{background-color: #1fc8db;}
.bg-green{background-color: green;}
.bg-grey{background-color: grey;}
.bg-white{background-color: #ffffff;}
.bg-black{background-color: black}
.bg-red{background-color: red;}
.white{color: #ffffff!important;}

.opa10{opacity: 1;}
.opa9{opacity: 0.9;}
.opa8{opacity: 0.8;}
.opa7{opacity: 0.7;}
.opa6{opacity: 0.6;}
.opa5{opacity: 0.5;}
.opa4{opacity: 0.4;}
.opa3{opacity: 0.3;}
.opa2{opacity: 0.2;}
.opa1{opacity: 0.1;}
.opa0{opacity: 0;}



.border{border: 1px solid red!important;}
.border-red{border: 1px solid red!important;}
.border-white{border: 1px solid white!important;}
.noborder{border: none;}



.pointer {cursor: pointer;}
.crosshaird {cursor: crosshair;}
.cursor {cursor: default;}


.z-1 { z-index: -1; }
.z0 { z-index: 0; }
.z1 { z-index: 1; }
.z2 { z-index: 2; }
.z3 { z-index: 3; }
.z4 { z-index: 4; }
.z5 { z-index: 5; }
.z6 { z-index: 6; }
.z7 { z-index: 7; }
.z8 { z-index: 8; }
.z9 { z-index: 9; }
.z10 { z-index: 10; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9997 { z-index: 9997; }
.z9998 { z-index: 9998; }
.z9999 { z-index: 9999; }
.z10000 { z-index: 10000; }




.font-large { font-size: 27px;}
.font-medium { font-size: 18px;}
.font-small { font-size: 11px;}



.uppercase {text-transform: uppercase;}
.lowercase {text-transform: lowercase;}
.capitalize {text-transform: capitalize;}
.bold {font-weight: 800;}


.large {
  font-size: 21px;
}
.extra-large {
  font-size: 30px;
}

.imp-opa10 {opacity: 1!important;}
.imp1-opa10 {opacity: 1!important;}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.no-outline {
  outline: none;
}
