@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Lovers+Quarrel&display=swap');

@font-face {
  font-family: "P22 Da Vinci Forward";
  src: url("fonts/davinci.woff") format("woff");
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  color: white;
  font-size: 62.5%;
  box-sizing: border-box;
}

* {
  font-size: 1.6rem;
  color: #5A3131;
  font-family: 'Aclonica', sans-serif;
}

.davinci {
  font-family: "P22 Da Vinci Forward", sans-serif!important;
  font-size: 4.2rem;
  line-height: 4rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 1.8rem;
}

ul, li, figure {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

p {
  font-size: 1.6rem;
  line-height: 2.7rem;
  text-align: justify;
}
a {
  text-decoration: none;
}

.app-page {
  @media only screen and (min-width: 0) {
    width: 100%;
    height: 100%;
    background-image: url("../assets/old-paper-bg-pattern.jpg");
    header {
      width: 100%;
      top: 0;
      left: 0;
      padding: 12px 0 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      position: fixed;
      z-index: 999;
      background-color: $brick;
      .logo {
        width: 40px;
        margin-left: 22px;
      }
      button {
        margin-left: auto;
        margin-right: 24px;
        height: 34px;
        border: 2px solid $orange;
        border-radius: 12px;
        background-color: rgba($orange, 0.4);
        a {
          display: inline-block;
          padding: 2px 16px;
          transform: translateY(2px);
          color: $light-yellow;
        }
      }
      .menu-icon {
        margin-right: 22px;
        transform: translateY(-1px);
        display: block;
        font-size: 3rem;
        font-family: Arial, sans-serif;
        color: $light-yellow;
      }
      nav {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        position: fixed;
        background-color: $brick;
        z-index: 99;
        flex-direction: column;
        justify-content: space-between;
        ul {
          margin-top: 80px;
        }
        li span {
          margin: 24px 0;
          display: block;
          text-align: center;
          font-size: 1.8rem;
          color: $light-yellow;
        }
        i {
          display: block;
          margin-bottom: 60px;
          opacity: 0.8;
        }
        &.active {
          transform: translateX(0);
        }
        li.flex-center {
          margin-top: 48px;
          margin-right: 0;
        }
        figure {
          a {
            margin: 0;
            &:first-child {
              margin-right: 32px;
            }
            img {
              width: 40px;
              margin-right: 0;
            }
          }
        }
      }
    }
    section:not(.banner-desktop) {
      padding: 0 29px;
      h2, article, li, img, article {
        position: relative;
        z-index: 2;
      }
    }
    .inter-block-icon {
      margin: 24px 0;
      position: relative;
      z-index: 4;
    }
    .banner {
      padding-top: 70px;
      h1 { margin-bottom: 8px; }
      h4 { margin-top: 0; }
      img#rounded-icon {
        mix-blend-mode: darken;
        animation-name: spinning;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .sm-cta {
        margin-top: 8px;
        justify-content: center;
      }
      p {
        i, .light-yellow {
          color: #503800!important;
        }
      }
    }
    .banner-desktop {
      display: none;
    }
    b.signature {
      font-size: 4rem;
    }
    .first-letter::first-letter,
    .quotes {
      font-family: 'Lovers Quarrel', cursive;
      font-size: 5rem;
      padding-right: 4px;
    }
    .gallery-component {
      width: 100%;
      max-width: 320px;
      height: 331px;
      background-image: url('../assets/gallery-wall.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .full-story {
      article p {
        overflow: hidden;
        transition: height 1s ease-in-out;
      }
    }
    button.read-more {
      height: 130px;
      margin: 0 auto 44px;
      padding-bottom: 12px;
      background: transparent;
      border: none;
      transition: opacity 1s ease-in-out, height 1s ease-in-out;
      span {
        margin-top: -45px;
      }
    }
    .hide-read-more {
      //height: 0.1px!important;
      //opacity: 0;
      //pointer-events: none;
      margin-top: 24px!important;
    }
    .roadmap {
      > ul {
        border-left: 2px solid #7E3F3B;
        li {
          margin-left: 28px;
          img { display: none; }
          h3 {
            margin-bottom: 0;
            position: relative;
            font-size: 1.9rem;
          }
          h3:before {
            width: 37px;
            height: 42px;
            content: "";
            display: block;
            position: absolute;
            left: -50px;
            top: 7px;
            background-image: url('../assets/sigiliu.png');
          }
          p { margin-top: 0; }
          li {
            list-style-type: initial;
          }
        }
      }
      > p {
        font-size: 2rem;
      }
    }
    .faq {
      h3 {margin-bottom: 12px;}
      h3 span {
        font-size: 2rem;
        line-height: 3rem;
      }
      p {
        margin-top: 0;
      }
    }
    .brain-icon {
      width: 80px;
    }
    .team {
      img {
        mix-blend-mode: multiply;
        width: 180px;
      }
      p {
        max-width: 260px;
        margin: 0 auto 36px;
      }
    }
    .team + figure {
      margin: 0 0 48px;
      .boat-icon {
        margin-top: 48px;
        width: 100px;
        opacity: 0.7;
        mix-blend-mode: color-burn;
      }
    }
    .social-media {
      padding-bottom: 100px!important;
      p {
        max-width: 680px;
        margin: 0 auto 36px;
        text-align: center;
        font-size: 1.9rem;
        span {
          font-size: 2.2rem;
        }
      }
      figure {
        width: 160px;
        margin: 0 auto;
      }
      img {
        align-self: center;
        mix-blend-mode: multiply;
        width: 60px;
      }
    }
    .full-story,
    .faq,
    .roadmap {
      max-width: 632px;
      margin: 0 auto;
    }
    /* ROADMAP ANIMATION */
    .left,
    .right {
      transform: translateX(100%);
    }
    footer {
      margin-top: 48px;
      padding: 18px 24px 0;
      background-color: rgba(0, 0, 0, 0.7);
      position: relative;
      z-index: 2;
      .logo {
        width: 50px;
        margin-right: 50px;
      }
      nav {
        height: 90px;
        max-width: 220px;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        a {
          //width: 120px;
          padding-bottom: 8px;
          color: $light-yellow;
        }
      }
      > figure {
        display: none;
      }
    }
    figure.sm-cta {
      img {
        margin-left: 12px;
        width: 36px!important;
      }
      a {
        margin-right: 24px;
        padding: 6px 32px;
        border: 3px solid $brick;
        color: $brick;
        border-radius: 40px;
        transition: transform 0.1s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .light-yellow {
      color: $light-yellow!important;
    }
  }

  @media only screen and (min-width: 768px) {
    header {
      padding-top: 0;
      padding-bottom: 0;
      .menu-icon {
        display: none;
      }
      #connect-wallet {
        margin-left: auto;
      }
      nav {
        position: relative;
        transform: translateX(0);
        display: inline-block;
        flex: 0;
        ul {
          margin-top: 0;
          margin-right: 24px;
          display: flex;
          li {
            cursor: pointer;
            margin-right: 24px;
            a {font-size: 1.6rem;}
            &:hover {
              text-decoration: underline;
            }
            &.flex-center {
              margin-top: 4px;
            }
            figure {
              a:first-child {
                margin-right: 16px;
              }
              img {
                width: 30px;
              }
            }
          }
        }
        i { display: none; }
      }
    }
    .banner p,
    .faq {
      max-width: 632px;
    }
    .banner {
      padding: 0;
      p {
        margin: 48px auto 0;
      }
    }
    .roadmap {
      h2 {
        margin-top: 48px;
        text-align: center;
      }
      > ul, > ul + p {
        width: 400px;
        margin: 0 auto;
      }
      ul {margin-bottom: 24px;}
    }
    .gallery-component {
      max-width: 100%;
    }
    .faq {
      margin-bottom: 48px;
    }
    .team p {
      margin: 0 auto 36px;
    }
    footer {
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      nav {
        height: 17px;
        flex-direction: row;
        justify-content: space-between;
        margin-right: auto;
        max-width: 400px;
      }
      > figure {
        display: block;
        img {
          width: 40px;
          margin: 2px 16px 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    header nav ul li span {
      transition: padding .2s ease-in-out, color .2s ease-in-out;
      &:hover {
        padding-left: 12px;
        padding-right: 4px;
        color: white;
      }
    }
    /* ROADMAP ANIMATION */
    .right {
      transform: translateX(100%);
    }
    .left {
      transform: translateX(-100%);
    }
    .roadmap {
      max-width: 900px;
      overflow: hidden;
      > ul {
        width: 100%;
        margin: 0 0 36px;
        border: none;
        position: relative;
        &:before {
          height: 100%;
          left: 49.4%;
          content: "";
          display: block;
          border-left: 4px dashed #7E3F3B;
          position: absolute;
        }
        li img {
          display: block;
          align-self: center;
        }
        > li:nth-child(odd) {
          margin-left: 0;
          img {
            margin-left: auto;
            //transform: translateX(58px);
          }
          div {
            width: 47%;
            margin-left: auto;
          }
        }
        > li:nth-child(even) {
          margin-left: 0;
          img {
            margin-right: auto;
            transform: translateX(-38px);
          }
          div {
            width: 47%;
            margin-left: 0;
            margin-right: auto;
            padding-right: 20px;
            text-align: right;
            h3:before {
              right: -42px;
              top: 10px;
              left: initial;
            }
            p {
              text-align: right;
            }
          }
        }
        > li:nth-child(1) img {width: 190px;}
        > li:nth-child(2) {
          li {list-style-type: none;}
          img {width: 230px;}
        }
        //> li:nth-child(4) img {transform: translateX(-80px);}
        > li:nth-child(7) img {width: 180px;}
      }
      > ul + p {
        width: 600px;
      }
    }
    .team {
      ul {
        max-width: 1120px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          width: 30%;
        }
      }
    }
    .sm-icons {
      transition: transform .1s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .banner {
      display: none;
    }
    .banner-desktop {
      margin-top: 65px;
      padding: 0 0 36px;
      overflow: hidden;
      display: block;
      h1,
      h4,
      article,
      p {
        position: relative;
        z-index: 1;
        color: #fae8d1;
      }
      h1,
      h4 {
        text-align: center;
        margin: 0;
      }
      h1 {
        padding-top: 100px;
        font-size: 3.2rem;
      }
      h4 {margin-top: 8px;}
      div.stripe {
        margin-top: 48px;
        padding: 24px 0;
        overflow: hidden;
        img.absolute {
          height: 100%;
          mix-blend-mode: overlay;
        }
      }
      article {
        max-width: 1080px;
        margin: 0 auto;
      }
      article.flex {
        p {
          width: 500px;
          b {color: white;}
        }
        > img:not(.absolute) {
          align-self: center;
          height: 480px;
        }
      }
      > figure {
        background-image: url('../assets/banner/bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        img:first-child {
          width: 66%;
          mix-blend-mode: overlay;
        }
        img:last-child {
          width: 33%;
          mix-blend-mode: multiply;
        }
      }
      .gallery {
        width: 500px;
        position: absolute;
        left: 560px;
        top: 50px;
        .swiper-slide-active {
          animation-name: fade-in;
          animation-duration: 1s;
          animation-timing-function: linear;
        }
        .swiper-slide-prev {
          animation-name: fade-out;
          animation-duration: 2s;
          animation-timing-function: linear;
        }
        .swiper-wrapper {
          transition-timing-function: ease-in-out;
        }
        img {
          height: 460px;
        }
      }
    }
    .full-story,
    .faq,
    .roadmap {
      max-width: 916px;
      li:nth-child(3) {
        img:first-child {
          transform: translateX(40px);
        }
        img:last-child {
          margin-left: 0;
        }
      }
    }
    figure.sm-cta {
      a {
        border-color: $light-yellow;
        color: $light-yellow;
      }
    }
  }

  @media only screen and (min-height: 900px) {
    .banner-desktop {
      figure {
        img:first-child {
          height: 100%;
          width: initial;
        }
        img:last-child {
          height: 100%;
          width: initial;
        }
      }
    }
  }

  @media only screen and (min-width: 1280px) and (max-height: 960px) {
    .banner-desktop {
      h1 {
        padding-top: 24px;
      }
      .stripe article {
        max-width: 1200px;
        p {
          width: 620px;
        }
      }
    }
  }

  @media only screen and (min-width: 1980px) {
  	.banner-desktop {
      h1 {
        font-size: 4rem;
      }
      .stripe article p {
        font-size: 1.8rem;
      }
    }
  }
}

/* Hiding classes - mobile/desktop */
@media only screen and (max-width: 768px) {
  .hide-on-desktop {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
  .hide-on-mobile {
    display: block;
  }
}

@keyframes spinning {
  from {transform: rotate(0);}
  to {transform: rotate(360deg);}
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fade-out {
  from {opacity: 1;}
  to {opacity: 0;}
}

.multiply {
  mix-blend-mode: multiply;
}
#container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 1310px;
  overflow: hidden;
}
